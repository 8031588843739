/* global styles placeholder */

.header_logo {
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
}

@media screen  and (forced-colors: active) and (prefers-color-scheme: light) {
  .header_logo{
    filter: invert(1) invert(23%) sepia(7%) saturate(11%) hue-rotate(322deg) brightness(97%) contrast(95%);
  }
}

sw-todo-summary,
sw-payment-summary,
sw-datainsights-summary,
sw-ccminvoice-summary,
sw-po-summary,
sw-tickets-summary,
sw-invoice-summary,
sw-recycling-tile,
sw-supplier-summary,
sw-sourcing-summary
 {
  width: 392px;
  height: 230px;
}

/* Header Contextual Menu CSS overwrite */
[aria-label="header"] .ms-OverflowSet-item .copilot-icon,
[aria-label="header"] .ms-OverflowSet-item [data-icon-name="Settings"] {
    color: #fff;
}
.ms-ContextualMenu-container .copilot-icon {
    color: #323130;
    margin-left: 16px;
}
.ms-ContextualMenu-container [aria-label="Help"] .ms-Button-textContainer{
    order: 2;
}
.ms-ContextualMenu-container [aria-label="Help"] [data-icon-name="help"] {
    color: #323130 !important;
    order: 1;
    margin-left: 20px;
}

/* Copilot */
@media (min-resolution: 150dpi) {
    .chatBody {
        height: auto !important;
    }
}